import sizes from "../scss/_settings/_settings.scss";

window.sizes = {};
Object.keys(sizes).map(function(key){
  window.sizes[key] = parseInt(sizes[key]);
});

window.points = [];

/* ------------------------------------------------------------------------- */
let request = function (url, data, after, fail) {
  $.post(url, {
    login: window._user.login,
    session: window._user.session,
    data: data
  }, after, "json").fail(fail);
};

let logout = () => {
  localStorage.setItem('user', '');
  location.reload();
};

let start = () => {
  // Login
  if (!window._user || !window._user.session || window._user.session.length !== 40) return;
  for (let name in window._user) {
    $('#' + name).val(window._user[name]);
  }

  // Validate login
  request('/valid', {}, (ret) => {
    if (!ret.success) logout();
  }, logout);

  // Geo-point
  if (!navigator.geolocation) {
    return alert("Geolocatie wordt niet ondersteund door uw browser!");
  }

  let timeout = 7 * 1000;
  let getPosition = () => {
    navigator.geolocation.getCurrentPosition((pos) => {
      console.log(pos);
      window.points.push({
        'lat': pos.coords.latitude,
        'lng': pos.coords.longitude,
        'acc': pos.coords.accuracy,
        'time': (+ new Date())
      });
      window.points = window.points.slice(-30);
    }, (err) => {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      window.points.push({
        'err': err.code,
        'msg': err.message,
        'time': (+ new Date())
      });
    }, {
      enableHighAccuracy: true,
      timeout: timeout,
      maximumAge: 0
    });
  };

  getPosition();
  setInterval(getPosition, timeout + 1);
};

let sos = () => {
  let code = null;
  let start = (+ new Date())/1000;
  let minutes = 30;
  let active = false;

  let sendGeoPoint = () => {
    if (window.points.length === 0 || active) return;
    active = true;
    request('/sos', {
      code: code,
      points: window.points
    }, (res) => {
      active = false;
      if (!code && res.error) {}
      code = res.code;
      window.points = [];
    }, () => {
      active = false;
      if (!code) {}
    });
  };

  sendGeoPoint();

  let sending = setInterval(() => {
    if ((+ new Date())/1000 > start + minutes * 60 * 60) {
      clearInterval(sending);
      window.reload();
    }
    sendGeoPoint();
  }, 3500);

  location.href = 'tel:09004112112';
  $('[data-sos-button]').removeClass('active');
};

/* ------------------------------------------------------------------------- */
const login = $('[data-login-page]');

window._user = JSON.parse(localStorage.getItem('user') || "{}");
if (window._user && window._user.session && window._user.session.length === 40) {
  login.remove();
  start();
}

$(document).on('click', '[data-login]:not(:disabled)', function() {
  const btn = $(this).attr('disabled', true).html('...');
  $.post('/login', {
    'login': $('#uname').val(),
    'password': $('#password').val()
  }, function (res) {
    btn.html('Login').attr('disabled', false);
    if (res.error) $('[data-login-result]').html('<span>' + res.error + '</span>');
    if (res.user) {
      window._user = res.user;
      localStorage.setItem('user', JSON.stringify(res.user));
      login.addClass('out');
      setTimeout(() => login.remove(), 350);
      start();
    }
  }, "json");
});

/* ------------------------------------------------------------------------- */
let sos_timer = null;
let sos_seconds = 0;

let close = () => {
  clearInterval(sos_timer);
  $('[data-sos-button]').removeClass('active');
  $('[data-sos-cancel]').css({ display: 'none' });
};

$(document).on('click', '[data-sos-button].call-process', function() {
  location.reload();
});

$(document).on('click', '[data-sos-button]:not(.active)', function() {
  $('[data-sos-button]').addClass('active');
  sos();
  return ;

  $('[data-sos-cancel]').css({ display: 'block' });

  sos_seconds = 3;
  $('[data-cancel-timer]').html(sos_seconds);
  sos_timer = setInterval(() => {
    sos_seconds -= 1;
    $('[data-cancel-timer]').html(sos_seconds);
    if (sos_seconds >= 0) return;
    close();
    sos();
    $('[data-sos-button]').addClass('call-process');
  }, 1000);
});

$(document).on('click', '[data-sos-cancel]', close);

$(document).on('click', '[data-close-profile]', () => {
  let p = $('[data-profile-page]').removeClass('open');
  setTimeout(() => p.css('display', 'none'), 350);
});

$(document).on('click', '[data-open-profile]', () => {
  let p = $('[data-profile-page]').css('display', 'block');
  setTimeout(() => p.addClass('open'), 50);
});

$(document).on('click', '[data-form] [data-send]:not(:disabled)', function(){
  const form = $(this).closest('[data-form]');
  const result = form.find('[data-result]');
  const button = form.find('[data-send]').attr('disabled', true);

  let data = {};
  form.find('input').each(function(){
    data[$(this).attr('name')] = $(this).val();
  });

  result.html('Verzending...');

  request(form.data('form'), data, (res) => {
    button.attr('disabled', false);
    result.html(res.message ? res.message : 'Fout bij verzenden');

    if (res.user) {
      window._user = res.user;
      localStorage.setItem('user', JSON.stringify(res.user));
    }
  }, () => {
    button.attr('disabled', false);
    result.html('Fout bij verzenden');
  });
});

$(document).on('click', '[data-logout]', () => {
  request('/logout', {}, logout, logout);
});




/* ------------------------------------------------------------------------- */
if('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js').then(function() {
    console.log('Service Worker Registered');
  }).catch(function(err) {
    console.log("No it didn't. This happened:", err)
  });
}

let deferredPrompt;
const addBtn = document.querySelector('.add-button');
addBtn.style.display = 'none';

window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;
  // Update UI to notify the user they can add to home screen
  addBtn.style.display = 'block';

  addBtn.addEventListener('click', (e) => {
    // hide our user interface that shows our A2HS button
    addBtn.style.display = 'none';
    // Show the prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      deferredPrompt = null;
    });
  });
});


/* ------------------------------------------------------------------------- */

if (screen && screen.orientation && screen.orientation.lock) screen.orientation.lock("natural");

require('../scss/common.scss');

// window.navigator.standalone

